import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'AUTO_SIGN_IN',
  'TOKEN_SIGN_IN',
  'SIGN_IN_REQUEST',
  'SIGN_IN_SUCCESS',
  'SIGN_IN_FAILURE',
  'SIGN_OUT',
  'IDENTIFY_SUCCESS',
  'IDENTIFY_FAILURE',
  'VERIFY_REQUEST',
  'VERIFY_FAILURE',
  'PERMISSIONS_REQUEST',
  'PERMISSIONS_SUCCESS',
  'PERMISSIONS_FAILURE',
]);

const actionCreators = createActions(
  actionTypes.SIGN_IN_REQUEST,
  actionTypes.VERIFY_REQUEST,
  actionTypes.AUTO_SIGN_IN,
  actionTypes.TOKEN_SIGN_IN,
  actionTypes.SIGN_IN_SUCCESS,
  actionTypes.SIGN_IN_FAILURE,
  actionTypes.SIGN_OUT,
  actionTypes.IDENTIFY_SUCCESS,
  actionTypes.IDENTIFY_FAILURE,
  actionTypes.VERIFY_FAILURE,
  actionTypes.PERMISSIONS_REQUEST,
  actionTypes.PERMISSIONS_SUCCESS,
  actionTypes.PERMISSIONS_FAILURE
);

export default {
  ...actionTypes,
  ...actionCreators,
};
