import {
  fork,
  call,
  take,
  put,
  cancel,
  all,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { values, head } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';

import { api } from 'app/services';
import {
  identifyByToken,
  startAnalytics,
  startIntercom,
  stopAnalytics,
} from '../../common/analytics';
import { watch } from '../../common/sagas';
import { actions as errorActions } from '../../error';
import actions from '../actions';
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from '../../session';
import { selectors as authSelectors } from '..';

function* isInitiatingSessionSelector() {
  return yield select(sessionSelectors.isFetching);
}

function* authorize({ email, password }) {
  const { response, error } = yield call(api.signIn, email, password);
  if (response) {
    const { token, tfaMissing, intercomUserHash } = head(
      values(response.session)
    ).attributes;
    if (tfaMissing) {
      yield call(api.storeItem, 'identificationToken', token);
      yield put(actions.identifySuccess());
    } else {
      yield call(api.storeItem, 'signInToken', token);
      yield call(api.storeItem, 'intercomUserHash', intercomUserHash);
      yield call(api.storeItem, 'userEmail', email);
      yield put(actions.signInSuccess({ token, email, intercomUserHash }));
    }
  } else {
    yield all([
      put(actions.signInFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* tokenSignIn(payload) {
  const { token, intercomUserHash, email } = payload;
  const authInProgress = yield isInitiatingSessionSelector();
  if (authInProgress) {
    yield take([
      sessionActions.sessionFailure,
      sessionActions.sessionClear,
      sessionActions.sessionSuccess,
    ]);
    yield put(sessionActions.sessionClear());
  }

  yield call(api.storeItem, 'signInToken', token);
  yield call(api.storeItem, 'userEmail', email);
  yield call(api.storeItem, 'intercomUserHash', intercomUserHash);
  yield put(actions.autoSignIn({ token, email, intercomUserHash }));
}

function* verify({ authenticatorCode }) {
  const { response, error } = yield call(
    api.twoFactorConfirmation,
    authenticatorCode
  );
  if (response) {
    const { token, email, intercomUserHash } = head(
      values(response.session)
    ).attributes;
    yield call(api.storeItem, 'signInToken', token);
    yield call(api.storeItem, 'intercomUserHash', intercomUserHash);
    yield call(api.storeItem, 'userEmail', email);
    yield put(actions.signInSuccess({ token, email, intercomUserHash }));
  } else {
    yield all([put(actions.verifyFailure(error))]);
    if (error !== 'invalid_token')
      return yield put(errorActions.apiError(error));
  }
}

function* signOut() {
  yield call(api.signOut);
  yield put(sessionActions.sessionClear());
  stopAnalytics();
}

function* fetchPermissions() {
  const { response, error } = yield call(api.fetchPermissions);

  if (response) {
    yield put(
      actions.permissionsSuccess(response.permissions.permissions.attributes)
    );
  } else {
    yield all([
      put(actions.permissionsFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* handleLocationChange({ payload }) {
  if (payload.isFirstRendering) return;

  const permissionsState = yield select(authSelectors.getPermissions);
  const { lastRequestAt } = permissionsState;

  const secondsToWait = 5;

  if (Date.now() - lastRequestAt > 1000 * secondsToWait) {
    yield put(actions.permissionsRequest());
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

function* watchFetchPermissions() {
  yield takeLatest(actions.permissionsRequest, fetchPermissions);
}

function* watchSignOut() {
  while (true) {
    yield take(actions.signOut);
    yield call(signOut);
  }
}

function* watchSignIn() {
  while (true) {
    const { payload } = yield take(actions.signInRequest);
    const task = yield fork(authorize, payload);
    const action = yield take([
      actions.signOut,
      actions.signInFailure,
      sessionActions.sessionClear,
    ]);
    if (action.type === actions.signOut) {
      yield cancel(task);
    }
  }
}

function* watchVerify() {
  while (true) {
    const { payload } = yield take(actions.verifyRequest);
    const task = yield fork(verify, payload);
    const action = yield take([
      actions.signOut,
      actions.signInFailure,
      actions.verifyFailure,
      sessionActions.sessionClear,
    ]);
    if (action.type === actions.SIGN_OUT) {
      yield cancel(task);
    }
  }
}

function* watchSignedIn() {
  while (true) {
    const { payload } = yield take([actions.signInSuccess, actions.autoSignIn]);
    startAnalytics();
    startIntercom(payload.email || '', payload.intercomUserHash || '');
    setTimeout(() => identifyByToken(payload.token), 0);

    yield put(actions.permissionsRequest());
  }
}

function* watchLocationChange() {
  yield takeLatest(LOCATION_CHANGE, handleLocationChange);
}

export default function* root() {
  yield fork(watchSignIn);
  yield fork(watchSignOut);
  yield fork(watchFetchPermissions);
  yield fork(watchLocationChange);
  yield fork(watchVerify);
  yield fork(watchSignedIn);
  yield watch(actions.tokenSignIn, tokenSignIn);
}
