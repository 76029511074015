import React from 'react';
import PropTypes from 'prop-types';

import { amount } from 'common/propTypes';
import stringUtils from 'common/stringUtils';

import Text from 'common/components/Text';
import CodeDisplay from 'common/components/CodeDisplay';

import styles from './index.module.css';

const PaymentLinkDetails = ({
  email,
  paymentUrl,
  emailSent,
  reference,
  totalAmount,
  customerName,
}) => (
  <>
    <h4 className={styles.header}>Payment link</h4>
    <div className={styles.root}>
      <div className={styles.urlDesktop}>
        <CodeDisplay code={paymentUrl} clipboard fullWidth />
      </div>
      <div className={styles.urlMobile}>
        <CodeDisplay
          code={paymentUrl}
          shareable
          shareText={stringUtils.buildInvoiceSharingString({
            reference,
            amount: totalAmount.value,
            currency: totalAmount.currency,
            customerName,
            url: paymentUrl,
          })}
          shareUrl={paymentUrl}
        />
      </div>
      <Text size="caption" color="rebranding-md-color-grey-60">
        Your customer can use this link to pay for this invoice.
      </Text>
      <Text size="caption" color="rebranding-md-color-grey-60">
        {emailSent
          ? `Invoice and payment link already sent to ${email}.`
          : 'Provide it to your customer so they can follow the payment instructions.'}
      </Text>
    </div>
  </>
);

PaymentLinkDetails.propTypes = {
  email: PropTypes.string.isRequired,
  paymentUrl: PropTypes.string.isRequired,
  reference: PropTypes.string,
  totalAmount: amount,
  customerName: PropTypes.string,
  sendIssuedEmail: PropTypes.bool.isRequired,
};

export default PaymentLinkDetails;
