import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import _ from 'lodash';

import constants from '../../common/constants';
import { actions as sessionActions } from '../../session';
import actions from '../actions';

const signInReducer = handleActions(
  {
    [combineActions(actions.SIGN_IN_SUCCESS, actions.AUTO_SIGN_IN)]: () => true,
    [combineActions(sessionActions.SESSION_CLEAR)]: () => false,
  },
  false
);

const signInErrorReducer = handleActions(
  {
    [actions.SIGN_IN_FAILURE]: (state, { payload }) => payload,
    [combineActions(
      actions.SIGN_IN_REQUEST,
      actions.SIGN_IN_SUCCESS,
      actions.AUTO_SIGN_IN,
      actions.IDENTIFY_SUCCESS,
      sessionActions.SESSION_CLEAR
    )]: () => null,
  },
  null
);

const indentifyReducer = handleActions(
  {
    [actions.IDENTIFY_SUCCESS]: () => true,
    [combineActions(sessionActions.SESSION_CLEAR)]: () => false,
  },
  false
);

const twoFactorErrorReducer = handleActions(
  {
    [actions.VERIFY_FAILURE]: (state, { payload }) => payload,
    [combineActions(
      actions.SIGN_IN_REQUEST,
      actions.SIGN_IN_SUCCESS,
      actions.AUTO_SIGN_IN,
      actions.IDENTIFY_SUCCESS,
      sessionActions.SESSION_CLEAR
    )]: () => null,
  },
  null
);

const isTwoFactorWrongReducer = handleActions(
  {
    [actions.VERIFY_FAILURE]: (state, { payload }) =>
      payload === constants.invalid_token,
    [actions.SIGN_IN_SUCCESS]: () => false,
  },
  false
);

const permissionsReducer = handleActions(
  {
    [actions.PERMISSIONS_SUCCESS]: (state, action) => ({
      loading: false,
      error: null,
      lastRequestAt: state.lastRequestAt,
      ...action.payload,
    }),
    [actions.PERMISSIONS_FAILURE]: (state, action) => ({
      loading: false,
      error: action.payload,
    }),
    [actions.PERMISSIONS_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      lastRequestAt: Date.now(),
    }),
  },
  { loading: false, error: null }
);
export default combineReducers({
  isAuthenticated: signInReducer,
  isIdentified: indentifyReducer,
  isTwoFactorWrong: isTwoFactorWrongReducer,
  twoFactorError: twoFactorErrorReducer,
  permissions: permissionsReducer,
  signInError: signInErrorReducer,
});

export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const isIdentified = (state) => state.auth.isIdentified;
export const isTwoFactorWrong = (state) => state.auth.isTwoFactorWrong;
export const twoFactorError = (state) => state.auth.twoFactorError;
export const getPermissions = (state) => state.auth.permissions;
export const signInError = (state) => state.auth.signInError;
export const upholdPermission = (state) =>
  _.get(state.auth.permissions, 'upholdConnect');
export const refundPermission = (state) =>
  _.get(state.auth.permissions, 'refunds');
export const storeCredentialsPermission = (state) =>
  _.get(state.auth.permissions, 'storeCredentials');
export const invoiceCreationPermission = (state) =>
  _.get(state.auth.permissions, 'invoiceCreation');
