import { call, fork, put, spawn } from 'redux-saga/effects';

import startupRootSaga from './startup';
import {
  sagas as sessionRootSaga,
  actions as sessionActions,
} from '../../session';
import { sagas as paymentsSagas } from '../../payments';
import { sagas as authRootSaga } from '../../auth';
import { sagas as settingsRootSaga } from '../../settings';
import { sagas as errorRootSaga } from '../../error';
import { sagas as integrationRootSaga } from '../../integrations';
import { sagas as featuresRootSaga } from '../../features';
import { sagas as onboardingRootSaga } from '../../onboarding';
import { sagas as settlementsRootSaga } from '../../settlements';

import constants from '../../common/constants';

function* handleErrors(error) {
  if (error === constants.invalid_token) {
    yield put(sessionActions.sessionClear());
  }
  // eslint-disable-next-line
  console.log(error);
  yield error;
}

function* respawnableSaga(sagas) {
  try {
    yield call(sagas);
  } catch (errors) {
    yield handleErrors(errors);
  } finally {
    yield spawn(respawnableSaga, sagas);
  }
}

function* watchers() {
  yield fork(respawnableSaga, authRootSaga);
  yield fork(respawnableSaga, sessionRootSaga);
  yield fork(respawnableSaga, onboardingRootSaga);
  yield fork(respawnableSaga, paymentsSagas.invoicesRootSagas);
  yield fork(respawnableSaga, paymentsSagas.ordersRootSagas);
  yield fork(respawnableSaga, settingsRootSaga);
  yield fork(respawnableSaga, featuresRootSaga);
  yield fork(respawnableSaga, errorRootSaga);
  yield fork(respawnableSaga, integrationRootSaga);
  yield fork(respawnableSaga, settlementsRootSaga);
}

function* mainSaga() {
  yield fork(watchers);
  yield call(startupRootSaga);
}

export default function* root() {
  try {
    yield call(mainSaga);
  } catch (error) {
    yield handleErrors(error);
  }
}
