import _ from 'lodash';

const mapCountries = (response, isAlpha2 = false) =>
  _.chain(response)
    .values()
    .map((country) => ({
      label: country.attributes.name,
      value: country.attributes[isAlpha2 ? 'alpha2' : 'alpha3'],
    }))
    .sortBy((country) => country.label.toLowerCase())
    .value();

const mapSectors = (response) => {
  const sortedSectors = _.chain(response)
    .values()
    .map((sector) => ({
      label: sector.attributes.division,
      value: sector.id,
    }))
    .sortBy((sector) => sector.label.toLowerCase())
    .value();

  //Move other to the end
  const otherIndex = _.findIndex(sortedSectors, ['label', 'Other']);
  sortedSectors.push(sortedSectors.splice(otherIndex, 1)[0]);

  return sortedSectors;
};

export { mapCountries, mapSectors };
