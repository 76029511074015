import { takeEvery, call, put } from 'redux-saga/effects';
import _ from 'lodash';

import constants, {
  failedToFetchChrome,
  failedToFetchFirefox,
  killSwitchStatusText,
} from 'common/constants';
import { actions as authActions } from 'auth';
import actions from '../actions';

const errorMap = {
  [failedToFetchChrome]: actions.failedFetch,
  [failedToFetchFirefox]: actions.failedFetch,
  [killSwitchStatusText]: actions.failedFetch,
  [constants.invalid_token]: authActions.signOut,
};

function* checkError({ payload: error }) {
  const actionToTrigger = errorMap[error] || errorMap[_.get(error, 'message')];

  if (actionToTrigger) yield put(actionToTrigger());
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

function* watchApiError() {
  yield takeEvery(actions.apiError, checkError);
}

export default function* root() {
  yield call(watchApiError);
}
