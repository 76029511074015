const apiURLForEnvironment = (hostname) => {
  const [subdomain, ...domain] = hostname.split('.');

  return `https://${[subdomain, 'api', ...domain].join('.')}/api`;
};

const apiURLForPreviewEnvironment = (hostname) => {
  const isXMoney = hostname.includes('xmoney.com');
  const subdomain = hostname.split('.')[1];

  if (isXMoney) {
    return `https://${subdomain}.api.dev.crypto.xmoney.com/api`;
  }

  return `https://${subdomain}.api.dev-utrust.com/api`;
};

export default (optionalHostname) => {
  const hostname = optionalHostname || window.location.hostname;

  if (
    hostname.includes('preview.dev-utrust.com') ||
    hostname.includes('preview.dev.crypto.xmoney.com')
  ) {
    return apiURLForPreviewEnvironment(hostname);
  }

  return apiURLForEnvironment(hostname);
};
