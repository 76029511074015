import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ordersReducer from '../../payments/reducers/orders';
import invoicesReducer from '../../payments/reducers/invoices';
import onboardingReducer from '../../onboarding/reducers';
import { reducers as authReducer, actions as authActions } from '../../auth';
import { reducers as settingsReducer } from '../../settings';
import { reducers as integrationsReducer } from '../../integrations';
import { reducers as sessionReducer } from '../../session';
import { reducers as errorReducer } from '../../error';
import { reducers as featuresReducer } from '../../features';
import { reducers as settlementsReducer } from '../../settlements';
import { history } from '../services';

const appReducer = combineReducers({
  settings: settingsReducer,
  auth: authReducer,
  error: errorReducer,
  features: featuresReducer,
  orders: ordersReducer,
  invoices: invoicesReducer,
  onboarding: onboardingReducer,
  integrations: integrationsReducer,
  settlements: settlementsReducer,
  router: connectRouter(history),
  session: sessionReducer,
});

const createRootReducer = (state, action) => {
  if (action.type === authActions.SIGN_OUT) {
    const { router, features } = state;
    return appReducer({ router, features }, action);
  }
  return appReducer(state, action);
};

export default createRootReducer;
