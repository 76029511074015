import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import Button from 'common/components/Button';
import Dropdown from 'common/components/Dropdown';
import Label from 'common/components/Label';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import TextBox from 'common/components/TextBox';
import Checkbox from 'common/components/Checkbox';
import TextArea from 'common/components/TextArea';
import formUtils from 'common/formUtils.js';
import { buyersCountriesSupport } from 'common/constants';
import { invoicePrefillProptype } from 'common/propTypes';

import { fiatCurrencyItems } from 'common/currencies';

import styles from './index.module.css';

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const initialValues = {
  amount: '',
  billingAddress: '',
  city: '',
  country: '',
  currency: '',
  customerEmail: '',
  customerName: '',
  description: '',
  postCode: '',
  state: '',
  sendIssuedEmail: ['on'],
};

const amountValidation = (value) =>
  formUtils.validatePositiveAmount(value) || formUtils.validateNumber(value);

const CantFind = ({ url }) => (
  <Text size="caption" color="rebranding-md-color-grey-60">
    If you {`don't`} find the country {`you're`} looking for, click{' '}
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.link}
    >
      here
    </a>{' '}
    for more information.
  </Text>
);

CantFind.propTypes = {
  url: PropTypes.string.isRequired,
};

const NewInvoice = ({
  countries,
  errorMessage,
  isFetchingCountries,
  prefillData,
  onSubmit,
  settlementCurrency,
  submitting,
}) => (
  <Formik initialValues={prefillData || initialValues} onSubmit={onSubmit}>
    {({
      handleSubmit,
      errors,
      touched,
      setFieldValue,
      handleBlur,
      handleChange,
    }) => (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        className={styles.root}
        data-test="storeName"
      >
        <form onSubmit={handleSubmit}>
          <Heading level="3" size="3" weight="medium">
            Customer information
          </Heading>
          <div className={styles.fullRow}>
            <Field name="customerName" validate={formUtils.validateRequired}>
              {({ field, meta }) => (
                <div className={styles.fieldWithCaption}>
                  <TextBox
                    id="customerName"
                    labelText="Name"
                    labelType="customerName"
                    placeholder=""
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    {...field}
                  />
                  <Text size="caption" color="rebranding-md-color-grey-60">
                    This name will be used for your records only
                  </Text>
                </div>
              )}
            </Field>
          </div>
          <div className={styles.fullRow}>
            <Field name="customerEmail" validate={formUtils.validateEmail}>
              {({ field, meta }) => (
                <div className={styles.customerEmail}>
                  <TextBox
                    id="customerEmail"
                    labelText="Email"
                    labelType="customerEmail"
                    placeholder=""
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    onChange={(e) => {
                      setFieldValue('customerEmail', e.target.value.trim());
                    }}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="sendIssuedEmail">
              {({ meta }) => (
                <Checkbox
                  label="Send invoice to customer via email"
                  name="sendIssuedEmail"
                  id="sendIssuedEmail"
                  checked={meta.value}
                  value={formUtils.checkBoxValue(meta.value)}
                  onBlur={handleBlur}
                  onChange={(e) => handleChange(e)}
                />
              )}
            </Field>
          </div>
          <div className={styles.fullRow}>
            <Field name="billingAddress" validate={formUtils.validateRequired}>
              {({ field, meta }) => (
                <TextBox
                  id="billingAddress"
                  labelText="Billing address"
                  labelType="billingAddress"
                  placeholder=""
                  full
                  error={meta.touched && meta.error}
                  errorText={meta.error}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className={classNames(styles.row, styles.half)}>
            <Field name="city" validate={formUtils.validateRequired}>
              {({ field, meta }) => (
                <TextBox
                  id="city"
                  labelText="City"
                  labelType="city"
                  placeholder=""
                  error={meta.touched && meta.error}
                  errorText={meta.error}
                  {...field}
                />
              )}
            </Field>
            <Field name="postCode" validate={formUtils.validateRequired}>
              {({ field, meta }) => (
                <TextBox
                  id="postCode"
                  labelText="Post code"
                  labelType="postCode"
                  placeholder=""
                  error={meta.touched && meta.error}
                  errorText={meta.error}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className={classNames(styles.row, styles.half)}>
            <Field name="state">
              {({ field, meta }) => (
                <TextBox
                  id="state"
                  labelText="State"
                  labelType="state"
                  optionalLabel="optional"
                  placeholder=""
                  error={meta.touched && meta.error}
                  errorText={meta.error}
                  {...field}
                />
              )}
            </Field>
            <span className={styles.dropdownWrapper}>
              <Field name="country" validate={formUtils.validateCountryObject}>
                {({ meta }) => (
                  <div className={styles.fieldWithCaption}>
                    <Label>Country</Label>
                    {isFetchingCountries ? (
                      <TextBox disabled id="countriesPlaceholder" />
                    ) : (
                      <Dropdown
                        isSearchable
                        id="country"
                        initialSelectedItem={meta.initialValue.value}
                        name="country"
                        placeholder=""
                        items={countries || []}
                        error={!!(meta.touched && meta.error)}
                        errorText={meta.error}
                        onChange={(value) => {
                          const country = countries.find(
                            (obj) => obj.value === value
                          );
                          setFieldValue('country', {
                            label: country?.label,
                            value: value || '',
                          });
                        }}
                      />
                    )}
                    <CantFind url={buyersCountriesSupport} />
                  </div>
                )}
              </Field>
            </span>
          </div>
          <br />
          <Heading level="3" size="3" weight="medium">
            Payment information
          </Heading>
          <div className={styles.captionWrapper}>
            <Text color="rebranding-md-color-grey-60" size="caption">
              Your customer will see the amount to pay in the currency you
              select below. They can choose to pay in BTC, ETH, UTK and others.
              You will get your payout in {settlementCurrency}.
            </Text>
          </div>
          <div className={classNames(styles.row, styles.half)}>
            <Field name="currency" validate={formUtils.validateRequired}>
              {({ meta }) => (
                <div>
                  <Label>Currency</Label>
                  <Dropdown
                    isSearchable
                    id="currency"
                    name="currency"
                    placeholder="Select currency"
                    initialSelectedItem={meta.initialValue}
                    items={fiatCurrencyItems}
                    error={!!(meta.touched && meta.error)}
                    errorText={meta.error}
                    onChange={(value) => {
                      setFieldValue('currency', value);
                    }}
                  />
                </div>
              )}
            </Field>

            <Field name="amount" validate={amountValidation}>
              {({ field, meta }) => (
                <TextBox
                  id="amount"
                  labelText="Amount"
                  labelType="amount"
                  placeholder="0.00"
                  error={meta.touched && meta.error}
                  errorText={meta.error}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className={styles.fullRow}>
            <Field
              name="description"
              validate={formUtils.validateInvoiceDescription}
            >
              {({ field, meta }) => (
                <TextArea
                  id="description"
                  labelText="Description"
                  labelType="description"
                  placeholder=""
                  error={meta.touched && meta.error}
                  errorText={meta.error}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className={styles.formFooter}>
            <div className={styles.buttonWrapper}>
              <Button
                kind="primary"
                loading={submitting}
                full
                disabled={formUtils.isSubmitDisabled(errors, touched)}
              >
                <button
                  type="submit"
                  id="generate-invoice-button"
                  data-test="generate-invoice-button"
                >
                  Generate invoice
                </button>
              </Button>
            </div>
            {errorMessage && (
              <div className={styles.errorMessage}>
                {errorMessage === 'max amount is 1,000,000 EUR' ? (
                  <>
                    Amount can&apos;t be over EUR 1.000.000.
                    <Text color="rebranding-md-color-grey-60">
                      <a
                        href="https://support.xmoney.com/en/articles/6824596-invoicing"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.link}
                      >
                        Learn more.
                      </a>
                    </Text>
                  </>
                ) : (
                  'Something went wrong. Please try again.'
                )}
              </div>
            )}
          </div>
        </form>
      </motion.div>
    )}
  </Formik>
);

const dropdownOption = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

NewInvoice.propTypes = {
  countries: PropTypes.arrayOf(dropdownOption),
  errorMessage: PropTypes.string,
  isFetchingCountries: PropTypes.bool,
  prefillData: invoicePrefillProptype,
  onSubmit: PropTypes.func.isRequired,
  settlementCurrency: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default NewInvoice;
