import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as errorActions } from 'error';
import LoadingPage from 'common/components/LoadingPage';
import FadeInTransition from 'common/components/FadeInTransition';
import { getProfile, getTwofactor, isFetchingProfile } from '../../reducers';
import actions from '../../actions';
import ErrorPage from '../../components/ErrorPage';
import Profile from '../../components/Profile';
import NewPassword from '../../containers/NewPassword';
import NewPasswordWithTwoFactor from '../../containers/NewPasswordWithTwoFactor';
import TwoFactorDisabled from '../../containers/TwoFactorDisabled';
import TwoFactorEnabled from '../../containers/TwoFactorEnabled';

import styles from './index.module.css';

const Personal = ({
  profile,
  twoFactor,
  submittingProfile,
  submitProfile,
  apiError,
  twoFactorAuthChange,
  loadPersonalPage,
}) => {
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!profile) loadPersonalPage();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  if (!profile) return <LoadingPage />;
  if (profile.error) return <ErrorPage />;
  const { email, firstName, lastName, id, tfaActive } = profile;
  const ChangePassword = tfaActive ? NewPasswordWithTwoFactor : NewPassword;
  const TwoFactorAuth = tfaActive ? TwoFactorEnabled : TwoFactorDisabled;
  const onSubmitProfile = (values) => {
    submitProfile({ ...values, id });
  };

  return (
    <FadeInTransition>
      <div className={styles.pageLayout}>
        <Profile
          email={email}
          firstName={firstName}
          lastName={lastName}
          isSubmitting={submittingProfile}
          onSubmit={onSubmitProfile}
        />
        <ChangePassword apiError={apiError} />
        <TwoFactorAuth
          apiError={apiError}
          id={id}
          twoFactorAuthChange={twoFactorAuthChange}
          {...twoFactor}
        />
      </div>
    </FadeInTransition>
  );
};

Personal.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
    tfaActive: PropTypes.bool,
    error: PropTypes.bool,
  }),
  twoFactor: PropTypes.shape({
    keyUri: PropTypes.string,
    secret: PropTypes.string,
    recoveryCode: PropTypes.string,
  }).isRequired,
  submittingProfile: PropTypes.bool.isRequired,
  submitProfile: PropTypes.func.isRequired,
  apiError: PropTypes.func.isRequired,
  twoFactorAuthChange: PropTypes.func.isRequired,
  loadPersonalPage: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    profile: getProfile(state),
    submittingProfile: isFetchingProfile(state),
    twoFactor: getTwofactor(state),
  }),
  {
    loadPersonalPage: actions.loadPersonalPage,
    submitProfile: actions.updateProfileRequest,
    twoFactorAuthChange: actions.twoFactorAuthChange,
    apiError: errorActions.apiError,
  }
)(Personal);
