const apiIntegrationsRoute = '/integrations/api_keys';
const forgotPasswordRoute = '/forgot-password';
const getStartedRoute = '/onboarding/get-started';
const integrationsRoute = '/integrations';
const invoicesRoute = '/payments/invoices';
const oldOrdersRoute = '/orders';
const onboardingRoute = '/onboarding';
const ordersRoute = '/payments/orders';
const paymentsRoute = '/payments';
const personalRoute = '/settings/personal';
const resetPasswordRoute = '/reset-password';
const rootRoute = '/';
const setPasswordRoute = '/onboarding/set_password';
const settingsRoute = '/settings';
const settlementsRoute = '/settlements';
const signInRoute = '/sign-in';
const signOutRoute = '/sign-out';
const signUpRoute = '/onboarding/sign-up';
const webhooksRoute = '/integrations/webhooks';

export {
  apiIntegrationsRoute,
  forgotPasswordRoute,
  getStartedRoute,
  integrationsRoute,
  invoicesRoute,
  oldOrdersRoute,
  onboardingRoute,
  ordersRoute,
  paymentsRoute,
  personalRoute,
  resetPasswordRoute,
  rootRoute,
  setPasswordRoute,
  settingsRoute,
  settlementsRoute,
  signInRoute,
  signOutRoute,
  signUpRoute,
  webhooksRoute,
};
