import React from 'react';
import { filter, first } from 'lodash';

import { useCompanyInfo, useContract } from 'common/hooks';
import LoadingPage from 'common/components/LoadingPage';
import FadeInTransition from 'common/components/FadeInTransition';
import ErrorPage from '../components/ErrorPage';
import Business from '../components/Business';

const BusinessContainer = () => {
  const { errorMessage, sectors, countries, businessInfo } = useCompanyInfo({
    withCountries: true,
    withSectors: true,
  });
  const [contract, isLoadingContract] = useContract();

  if (errorMessage) return <ErrorPage />;
  if (!sectors || !businessInfo || isLoadingContract) return <LoadingPage />;
  const { sectorId, country, ...businessProps } = businessInfo;
  const sectorObject = first(filter(sectors, { value: sectorId?.toString() }));
  const sectorLabel = sectorObject?.label;
  const countryObject = first(filter(countries, { value: country }));
  const countryLabel = countryObject?.label || country;

  return (
    <FadeInTransition>
      {' '}
      <Business
        {...businessProps}
        sector={sectorLabel}
        country={countryLabel}
        referenceFiatCurrency={contract?.referenceFiatCurrency}
      />
    </FadeInTransition>
  );
};

export default BusinessContainer;
