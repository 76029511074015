import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectors as featuresSelectors } from '../../features';
import * as settings from '../../settings';
import { pages as auth } from '../../auth';
import * as onboarding from '../../onboarding';
import * as payments from '../../payments';
import routeSelectionPages from '../../routeSelection';
import * as integrations from '../../integrations';
import * as settlements from '../../settlements';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {
  settingsRoute,
  forgotPasswordRoute,
  integrationsRoute,
  oldOrdersRoute,
  onboardingRoute,
  ordersRoute,
  paymentsRoute,
  resetPasswordRoute,
  rootRoute,
  settlementsRoute,
  signInRoute,
  signOutRoute,
} from './routes';

const RedirectToPayments = ({ location: { pathname } }) => {
  const uuid = pathname?.split('/orders')[1];
  return <Redirect to={ordersRoute + (uuid || '')} />;
};

RedirectToPayments.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const Router = ({ isFetchingFeatureFlags }) => {
  if (isFetchingFeatureFlags) return null;

  return (
    <Switch>
      <Route path={onboardingRoute} component={onboarding.pages} />
      <Route path={signInRoute} component={auth.SignInPage} />
      <PublicRoute
        path={forgotPasswordRoute}
        component={auth.ForgotPasswordPage}
      />
      <PublicRoute
        path={resetPasswordRoute}
        component={auth.ResetPasswordPage}
      />
      <PrivateRoute path={signOutRoute} component={auth.SignOutPage} />
      <PrivateRoute path={oldOrdersRoute} component={RedirectToPayments} />
      <PrivateRoute path={paymentsRoute} component={payments.pages} />
      <PrivateRoute path={settlementsRoute} component={settlements.pages} />
      <PrivateRoute path={settingsRoute} component={settings.pages} />
      <PrivateRoute path={integrationsRoute} component={integrations.pages} />
      <PrivateRoute path={rootRoute} component={routeSelectionPages} />
    </Switch>
  );
};

Router.propTypes = {
  isFetchingFeatureFlags: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isFetchingFeatureFlags: featuresSelectors.isFetchingFeatureFlags(state),
}))(Router);
