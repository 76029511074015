import { put, call, all } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import { actions as featuresActions } from '../../features';
import { api } from '../services';
import { actions as authActions } from '../../auth';

function* loadFromLocalStorage() {
  const signInToken = yield call(api.getItem, 'signInToken');
  const identificationToken = yield call(api.getItem, 'identificationToken');
  const intercomUserHash = yield call(api.getItem, 'intercomUserHash');
  const userEmail = yield call(api.getItem, 'userEmail');
  if (!isEmpty(signInToken)) {
    yield put(
      authActions.autoSignIn({
        email: userEmail,
        token: signInToken,
        intercomUserHash,
      })
    );
  }
  if (!isEmpty(identificationToken)) yield put(authActions.identifySuccess());
}

export default function* root() {
  yield all([
    call(loadFromLocalStorage),
    put(featuresActions.featuresRequest()),
  ]);
}
